import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import Mixin from "@/utils/mixin";
import { buttons } from "@/utils/buttons";
import { mapState } from "vuex";
export default {
  name: "RouterInfo",
  data: function data() {
    return {
      faTitle: this.activeRouterTitle,
      faPath: "",
      chiTitle: "",
      chiPath: "",
      disabledActions: [] // 禁用的按钮
    };
  },

  mixins: [Mixin],
  created: function created() {
    var _this = this;
    if (!this.pageBtnDisabled) {
      this.$store.commit("base/setPageBtnDisabled", true);
      window.GLOBAL.vbus.$on("pageBtnDisabled", function (data) {
        _this.disabledActions = data;
      });
    }
    // console.log('pageBtnDisabled created', this.pageBtnDisabled);
  },
  activated: function activated() {
    var _this2 = this;
    if (!this.pageBtnDisabled) {
      this.$store.commit("base/setPageBtnDisabled", true);
      window.GLOBAL.vbus.$on("pageBtnDisabled", function (data) {
        _this2.disabledActions = data;
      });
    }
    // console.log('pageBtnDisabled activated', this.pageBtnDisabled);
  },
  deactivated: function deactivated() {
    if (this.pageBtnDisabled) {
      this.$store.commit("base/setPageBtnDisabled", false);
      window.GLOBAL.vbus.$off("pageBtnDisabled", function () {});
    }
    // console.log('pageBtnDisabled deactivated', this.pageBtnDisabled);
  },
  beforeDestroy: function beforeDestroy() {
    if (this.pageBtnDisabled) {
      this.$store.commit("base/setPageBtnDisabled", false);
      window.GLOBAL.vbus.$off("pageBtnDisabled", function () {});
    }
    // console.log('pageBtnDisabled beforeDestroy', this.pageBtnDisabled);
  },

  watch: {
    routePath: {
      handler: function handler(nl, ol) {
        this.faTitle = "";
        this.faPath = "";
        this.chiTitle = "";
        this.chiPath = "";
        if (nl) {
          this.getFaByPath(nl);
        }
      },
      immediate: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState("base", ["pageBtnDisabled"])), {}, {
    // 操作按钮列表
    actions: function actions() {
      var _this3 = this;
      var data = [];
      buttons.some(function (item) {
        if (item.path == _this3.routePath) {
          data = item.actions;
          return true;
        }
      });
      return data;
    },
    // 模块
    module: function module() {
      var _this4 = this;
      var module = "";
      buttons.some(function (item) {
        if (item.path == _this4.routePath) {
          module = item.module;
          return true;
        }
      });
      return module;
    }
  }),
  methods: {
    handleGoToFaPath: function handleGoToFaPath() {
      if (!this.chiTitle || !this.chiPath) return;
      this.$router.push(this.faPath);
    },
    // 过滤path上的参数
    filterPathQuery: function filterPathQuery(path) {
      if (path.indexOf("?") !== -1) {
        return path.split("?")[0];
      }
      return path;
    },
    getFaByPath: function getFaByPath(val) {
      var _this5 = this;
      this.menuList.forEach(function (faItem) {
        faItem.children && faItem.children.length && faItem.children.forEach(function (chiItem) {
          // 一级页面
          if (chiItem.path == val) {
            _this5.faTitle = chiItem.name;
            _this5.faPath = chiItem.path;
          } else {
            chiItem.children && chiItem.children.length && chiItem.children.forEach(function (graItem) {
              // 二级隐藏页面
              if (graItem.path == _this5.filterPathQuery(val)) {
                _this5.faTitle = chiItem.name;
                _this5.faPath = chiItem.path;
                _this5.chiTitle = graItem.name;
                _this5.chiPath = graItem.path;
              }
            });
          }
        });
      });
    },
    handleClicked: function handleClicked(module, action) {
      window.GLOBAL.vbus.$emit("topBtnClicked", {
        module: module,
        action: action
      });
    }
  }
};