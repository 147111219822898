var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logout"
  }, [_c("a-button", {
    attrs: {
      type: "danger",
      size: "large",
      shape: "round",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleLogout
    }
  }, [_vm._v(_vm._s(_vm.$t("common.logout")))])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };