var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("img", {
    staticClass: "menu_icon",
    attrs: {
      src: _vm.menuIcon,
      alt: ""
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };