var ICONIDMAP = {
  '0': require('../../assets/img/menuIcon/category.png'),
  '1': require('../../assets/img/menuIcon/brand.png'),
  '2': require('../../assets/img/menuIcon/good.png'),
  '3': require('../../assets/img/menuIcon/size.png'),
  '4': require('../../assets/img/menuIcon/series.png'),
  '5': require('../../assets/img/menuIcon/check.png'),
  '6': require('../../assets/img/menuIcon/operating.png'),
  '7': require('../../assets/img/menuIcon/acount.png'),
  '8': require('../../assets/img/menuIcon/role.png'),
  '9': require('../../assets/img/menuIcon/sys.png'),
  '10': require('../../assets/img/menuIcon/order.png'),
  '11': require('../../assets/img/menuIcon/yml.png'),
  '12': require('../../assets/img/menuIcon/car.png'),
  '13': require('../../assets/img/menuIcon/hot.png'),
  '14': require('../../assets/img/menuIcon/quick.png'),
  '15': require('../../assets/img/menuIcon/ruler.png')
};
export { ICONIDMAP };