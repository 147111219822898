import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "router_info"
  }, [_c("div", {
    staticClass: "top_text"
  }, [_vm.faTitle ? _c("span", {
    staticClass: "click_router",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.handleGoToFaPath();
      }
    }
  }, [_vm._v(_vm._s(_vm.faTitle))]) : _vm._e(), _vm.chiTitle ? _c("span", [_c("span", [_vm._v(" > ")]), _vm._v(_vm._s(_vm.chiTitle))]) : _vm._e(), !_vm.faTitle && !_vm.chiTitle ? _c("span", [_vm._v(_vm._s(_vm.activeRouterTitle))]) : _vm._e(), _c("div", {
    staticClass: "actions"
  }, [_vm._l(_vm.actions, function (item, key) {
    return [item.action != "checkall" ? _c("a-button", {
      directives: [{
        name: "hasPermission",
        rawName: "v-hasPermission",
        value: ["".concat(_vm.module, ":btn:").concat(item.action)],
        expression: "[`${module}:btn:${item.action}`]"
      }],
      key: key,
      attrs: {
        type: item.type,
        disabled: _vm.disabledActions.includes(item.action)
      },
      on: {
        click: function click($event) {
          return _vm.handleClicked(_vm.module, item.action);
        }
      }
    }, [_vm._v(_vm._s(item.name))]) : _c("a-button", {
      key: key,
      attrs: {
        type: item.type,
        disabled: _vm.disabledActions.includes(item.action)
      },
      on: {
        click: function click($event) {
          return _vm.handleClicked(_vm.module, item.action);
        }
      }
    }, [_vm._v(_vm._s(item.name))])];
  })], 2)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };