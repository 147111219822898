import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters } from "vuex";
export default {
  name: "UserInfo",
  computed: _objectSpread(_objectSpread({}, mapGetters('base', ['getUserInfo'])), {}, {
    localUserInfo: function localUserInfo() {
      return this.getUserInfo;
    }
  }),
  data: function data() {
    return {};
  },
  created: function created() {},
  methods: {}
};