import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from "vuex";
import { keepAliveWhiteList } from "../config/interceptors/constant";
import LSMenu from './LeftSideMenu';
import RouterInfo from './RouterInfo';
export default {
  name: "BasicLayout",
  components: {
    LSMenu: LSMenu,
    RouterInfo: RouterInfo
  },
  data: function data() {
    return {
      keepAliveWhiteList: keepAliveWhiteList
    };
  },
  computed: _objectSpread({}, mapState({
    userInfo: function userInfo(state) {
      return state.base.userInfo;
    }
  })),
  watch: {},
  mounted: function mounted() {},
  // 销毁定时器
  beforeDestroy: function beforeDestroy() {},
  created: function created() {},
  methods: {}
};