var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "n_basic_layout"
  }, [_c("LSMenu", {
    staticClass: "l_s_menu"
  }), _c("RouterInfo", {
    staticClass: "router_info"
  }), _c("div", {
    staticClass: "router_view_container hidden-scroll"
  }, [_c("router-view")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };