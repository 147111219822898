import "core-js/modules/es.number.constructor.js";
import { ICONIDMAP } from './config';
export default {
  name: "MenuIcon",
  props: {
    menuPath: String | Number
  },
  data: function data() {
    return {
      menuIcon: ICONIDMAP[this.menuPath % 16]
    };
  },
  computed: {},
  methods: {}
};