import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.localUserInfo.token ? _c("div", {
    staticClass: "user_info"
  }, [_c("img", {
    staticClass: "img_box",
    attrs: {
      src: _vm.localUserInfo.headimgurl,
      alt: ""
    }
  }), _c("span", {
    staticClass: "wel_text"
  }, [_vm._v(_vm._s(_vm.$t("common.leftMenu.welcome")) + ", " + _vm._s(_vm.localUserInfo.realname))]), _c("div", {
    staticClass: "user_type"
  }, [_vm._v(" " + _vm._s(_vm.localUserInfo.name) + " ")])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };